<template>
  <b-overlay :show="isLoading">
    <b-container>
      <b-row>
        <b-col cols="12" md="6" v-for="room of rooms" :key="room.id">
          <b-card
            :title="room.name"
            :img-src="room.room_image"
            :img-alt="room.name"
            img-top
            class="mb-2"
          >
            <b-card-text>
              {{ room.description }}
            </b-card-text>

            <b-button :href="room.room_url" variant="primary"
              >Enter room</b-button
            >
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </b-overlay>
</template>
<script>
export default {
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    rooms() {
      return this.$store.getters["Rooms/all"];
    },
  },
  methods: {
    async doFetch() {
      this.isLoading = true;
      await this.$store.dispatch("Rooms/fetchAll");
      this.isLoading = false;
    },
  },
  created() {
    this.doFetch();
  },
};
</script>
<style lang="scss"></style>
